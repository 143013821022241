import React, { startTransition, StrictMode } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';
import { hydrateRoot } from 'react-dom/client';
import Layout from './layout';
function hydrate() {
    startTransition(() => {
        const app = document.getElementById('app');
        hydrateRoot(app, React.createElement(BrowserRouter, null,
            React.createElement(CookiesProvider, null,
                React.createElement(DebugMode, { debug: app.classList.contains('debug') },
                    React.createElement(Layout, { context: window.context })))));
    });
}
function DebugMode({ debug, children }) {
    if (debug) {
        return React.createElement(StrictMode, null, children);
    }
    return children;
}
typeof requestIdleCallback === 'function' ? requestIdleCallback(hydrate) : setTimeout(hydrate, 1);
