import { Link, useLocation, useSearchParams } from 'react-router-dom';
import React, { lazy, Suspense, useContext, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Icon from '@nguyengg/lambdajs/components/icon';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { PageContext } from 'app/context';
import useIncognito from 'app/videos/incognito';
const VideoSearchModal = lazy(() => import(
/* webpackPrefetch: true */
/* webpackChunkName: "videos-search-modal" */
'app/videos/browse/search-modal'));
export default function MyNavbar() {
    const { session } = useContext(PageContext);
    const { pathname } = useLocation();
    const { incognito, toggleIncognito } = useIncognito();
    const [searchParams] = useSearchParams();
    const [showSearch, setShowSearch] = useState(false);
    // the site has two types of asset: video and gallery.
    // each asset type has their browse, upload, and view pages.
    return (React.createElement(Navbar, { bg: "body-tertiary", collapseOnSelect: true, expand: "md", sticky: "top" },
        React.createElement(Container, { fluid: true },
            React.createElement(Navbar.Toggle, null),
            React.createElement(Navbar.Collapse, { className: "justify-content-between" },
                pathname.startsWith('/videos/') ? (React.createElement(Nav, null,
                    React.createElement(Nav.Link, { as: Link, active: pathname !== '/videos/upload', reloadDocument: pathname === '/videos/', to: "/videos/" }, "Browse"),
                    React.createElement(Nav.Link, { as: Link, active: pathname === '/videos/upload', disabled: true, reloadDocument: pathname === '/videos/upload', to: "/videos/upload" }, "Upload"),
                    React.createElement(NavDropdown, { title: "Video" },
                        React.createElement(NavDropdown.Item, { as: Link, to: pathname === '/videos/upload' ? '/galleries/upload' : '/galleries/' }, "Galleries"),
                        React.createElement(NavDropdown.Item, { as: Link, active: pathname === '/videos/', reloadDocument: pathname === '/videos/', to: "/videos/" }, "Videos")))) : (React.createElement(Nav, null,
                    React.createElement(Nav.Link, { as: Link, active: pathname !== '/galleries/upload', reloadDocument: pathname == '/galleries/', to: "/galleries/" }, "Browse"),
                    React.createElement(Nav.Link, { as: Link, active: pathname === '/galleries/upload', disabled: true, reloadDocument: pathname === '/galleries/upload', to: "/galleries/upload" }, "Upload"),
                    React.createElement(NavDropdown, { title: "Gallery" },
                        React.createElement(NavDropdown.Item, { as: Link, active: pathname === '/galleries/', reloadDocument: pathname === '/galleries/', to: "/galleries/" }, "Galleries"),
                        React.createElement(NavDropdown.Item, { as: Link, to: pathname === '/galleries/upload' ? '/videos/upload' : '/videos/' }, "Videos")))),
                React.createElement("div", null, session.sub && pathname.startsWith('/videos/') && (React.createElement(React.Fragment, null,
                    React.createElement(Form, { className: "d-flex mx-auto" },
                        React.createElement(Form.Control, { className: "ms-3", disabled: !pathname.startsWith('/videos/'), onClick: ({ currentTarget }) => {
                                currentTarget.blur();
                                setShowSearch(true);
                            }, placeholder: "Search", type: "search" })),
                    React.createElement(Suspense, null,
                        React.createElement(VideoSearchModal, { key: 
                            /* usage of key here forces search modal to reset state when query parameters change */
                            searchParams.toString(), onHide: () => setShowSearch(false), show: showSearch }))))),
                React.createElement("div", { className: "d-flex flex-row" },
                    session.sub && pathname.startsWith('/videos/') && (React.createElement(Form.Switch, { checked: incognito, className: "me-3 pt-2", label: React.createElement(Icon, { name: "incognito", fill: "white" }), onChange: toggleIncognito })),
                    React.createElement(Navbar.Text, null, session.sub ? (React.createElement(Link, { to: "/logout", reloadDocument: true }, "Sign out")) : (React.createElement(Link, { to: `/login?redirectUri=${pathname}`, reloadDocument: true }, "Sign in"))))))));
}
