import { createContext } from 'react';
import { List } from 'immutable';
export const PageContext = createContext({
    session: {},
    fallback: {},
});
export const ErrorStackContext = createContext({
    errors: List(),
    pushError() { },
});
