import { useCookies } from 'react-cookie';
export default function useIncognito() {
    const [cookies, setCookie, removeCookie] = useCookies(['incognito']);
    return {
        incognito: cookies.incognito ?? true,
        toggleIncognito() {
            if (cookies.incognito ?? true) {
                setCookie('incognito', false, {
                    path: '/',
                    secure: true,
                    sameSite: 'lax',
                });
            }
            else {
                removeCookie('incognito');
            }
        },
    };
}
